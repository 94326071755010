import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
    * {
        font-family: 'Muli', sans-serif;
		box-sizing: border-box;
		:focus {
			outline: none;
		}
    }
    html, body {
        padding: 0;
        margin: 0;
        font-family: sans-serif;
		scroll-behavior: smooth;
    }
`;

export const colors = {
	primary: '#BFA3D0',
	secondary: '#190264',
	light: '#FFFFFF',
	yellow: '#FFBF00',
	lighterYellow: '#FFBF0066',
	purple: '#A379BC',
	ligtherPurple: '#A379BC66',
	brown: '#F6AD88',
	lighterBrown: '#F6AD8866',
	lighterPurpleShadow: '#1902641F',
	darkerPurpleShadow: '#1902643D',
	greyShadow: '#00000029',
	basicPurple: '#7000C5',
};
