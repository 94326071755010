import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import languages from 'src/resources/languages';

const Menu = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 70px;
`;

const Option = styled.div`
	margin: 0 3px;
	cursor: pointer;
	transition: all 0.5s;
	width: 30px;
	height: 20px;
	& img {
		opacity: ${props => (props.opacity ? 0.5 : 1)};
		width: 100%;
	}
`;

const LanguageSelector = () => {
	const { i18n } = useTranslation();
	const [selected, setSelected] = useState(i18n.language);

	const select = e => {
		const lang = e.currentTarget.getAttribute('name');
		setSelected(lang);
		i18n.changeLanguage(lang);
	};

	return (
		<Menu className="selector">
			{languages.map((lang, index) => (
				<Option opacity={selected !== lang.iso} key={index} name={lang.iso} onClick={select} selected={selected === lang.iso}>
					<img src={lang.image} alt={lang.iso} />
				</Option>
			))}
		</Menu>
	);
};

export default LanguageSelector;
