import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import styled, { css, ThemeProvider } from 'styled-components';
import { LanguageSelector } from 'src/components/utils';
import { useTranslation } from 'react-i18next';
import connectLogo from 'src/images/connect_logo.png';
import useClickAway from 'src/hooks/useClickAway';
import useScroll from 'src/hooks/userScroll';

const Wrapper = styled.header`
	width: 100%;
	position: fixed;
	z-index: 100;
	transition: background-color 0.3s;
	${props =>
		props.theme.scrolledDown &&
		css`
			background: rgba(255, 255, 255, 0.9);
			box-shadow: 0 1px 5px ${props.theme.colors.darkerPurpleShadow};
		`}
`;

const NavBar = styled.nav`
	width: 75%;
	min-width: 220px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	transition: margin 0.3s;
	margin: ${props => (props.theme.scrolledDown ? '10px auto' : '20px auto')};
	position: ${props => !props.theme.open && 'relative'};
	@media (max-width: 1200px) {
		flex-wrap: wrap;
	}
	@media (max-width: 768px) {
		width: 92%;
		margin: 10px auto;
	}
`;

const ImgLink = styled(Link)`
	& img {
		width: 100%;
		transition: width 0.3s;
	}
	@media (max-width: 768px) {
		& img {
			width: 150px;
		}
	}
`;

const MenuButton = styled.div`
	padding: 11px 0;
	cursor: pointer;
	z-index: 100;
	position: absolute;
	right: ${props => (props.theme.open ? '20px' : 0)};
	@media (min-width: 1200px) {
		display: none;
	}
`;

const Hamburger = styled.div`
	width: 25px;
	height: 3px;
	position: relative;
	background: ${props => props.theme.colors.primary};
	transition: transform 0.5s ease;
	:before,
	:after {
		content: '';
		height: 100%;
		width: 100%;
		position: absolute;
		top: -8px;
		background: ${props => props.theme.colors.primary};
		transition: transform 0.3s ease;
	}
	:after {
		top: 8px;
	}
	${props =>
		props.theme.open &&
		css`
			background: transparent;
			:before {
				top: 0;
				transform: rotate(-45deg);
			}
			:after {
				top: 0;
				transform: rotate(45deg);
			}
		`}
`;

const MenuItems = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 1200px) {
		position: fixed;
		width: 300px;
		height: 100vh;
		top: 0;
		right: ${props => (props.theme.open ? 0 : '-315px')};
		padding: 90px 0;
		flex-direction: column;
		justify-content: flex-start;
		background: white;
		transition: right 0.5s;
		box-shadow: -2px 0 10px ${props => props.theme.colors.greyShadow};
		& > * {
			margin: 15px 0 !important;
		}
	}
	@media (max-width: 500px) {
		width: ${props => props.theme.open && '100%'};
	}
`;

const MenuLink = styled.a`
	display: block;
	text-decoration: none;
	text-align: center;
	margin: 15px 10px;
	transition: color 0.5s;
	font-weight: bold;
	color: ${props => props.theme.colors.secondary};
	:nth-last-child(2) {
		color: ${props => props.theme.colors.light};
		background: ${props => props.theme.colors.primary};
		border-radius: 6px;
		padding: 12px 25px;
		font-weight: bold;
		margin: 0 10px;
	}
	:not(:nth-last-child(2)):hover {
		color: ${props => props.theme.colors.primary};
	}
`;

const MenuHover = styled.div`
	display: block;
	text-decoration: none;
	text-align: center;
	margin: 15px 10px;
	transition: color 0.5s;
	font-weight: bold;
	color: ${props => props.theme.colors.secondary};
	cursor: pointer;
	:hover {
		color: ${props => props.theme.colors.primary};
	}
`;

const DropdownContent = styled.div`
	display: none;
	position: absolute;
	background-color: ${props => props.theme.colors.light};
	min-width: 240px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	color: black;
	border-radius: 5px;
`;

const Dropdown = styled.div`
	position: relative;
	display: inline-block;
	&:hover ${DropdownContent} {
		display: block;
	}
`;

const InnerMenuLink = styled.a`
	display: block;
	text-decoration: none;
	text-align: center;
	margin: 15px 10px;
	transition: color 0.5s;
	font-weight: bold;
	color: ${props => props.theme.colors.secondary};
	&:hover {
		color: ${props => props.theme.colors.primary};
	}
`;

const Header = () => {
	const { t: translate, i18n } = useTranslation();

	useEffect(() => {
		setIsDesktop(window.innerWidth > 1200);
	}, []);

	const [open, setOpen] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);
	const [scrolledDown, setScrolledDown] = useState(false);

	const menuRef = useRef(null);

	const closeMenu = () => open && setOpen(false);

	useClickAway(menuRef, closeMenu);
	useScroll(() => {
		if (open) closeMenu();
		if (window.scrollY > 2 && !scrolledDown) {
			setScrolledDown(true);
		} else if (window.scrollY <= 2 && scrolledDown) {
			setScrolledDown(false);
		}
	});
	const { aboutMe, aboutCoaching, isItForMe, services, contact, businessCoaching, languageCoaching } = translate('nav', {
		returnObjects: true,
	});
	return (
		<ThemeProvider theme={{ open, scrolledDown }}>
			<Wrapper>
				<NavBar>
					<ImgLink to="/">
						<img style={{ width: '200px' }} src={connectLogo} alt="Logo" />
					</ImgLink>
					<MenuButton onClick={() => setOpen(!open)}>
						<Hamburger />
					</MenuButton>
					<MenuItems className="nav-items" ref={menuRef}>
						<MenuLink key={'aboutMe'} href={aboutMe.link} onClick={closeMenu}>
							{aboutMe.text}
						</MenuLink>
						<MenuLink key={'aboutCoaching'} href={aboutCoaching.link} onClick={closeMenu}>
							{aboutCoaching.text}
						</MenuLink>
						<MenuLink key={'isItForMe'} href={isItForMe.link} onClick={closeMenu}>
							{isItForMe.text}
						</MenuLink>
						{isDesktop
							? [
									<Dropdown>
										<MenuHover key={'services'} href={services.link}>
											{services.text}
										</MenuHover>

										<DropdownContent>
											<InnerMenuLink key={'services'} href={services.link} onClick={closeMenu}>
												{services.text}
											</InnerMenuLink>

											<InnerMenuLink key={'business-coaching'} href={businessCoaching.link} onClick={closeMenu}>
												{businessCoaching.text}
											</InnerMenuLink>
											{i18n.language === 'hu' && (
												<InnerMenuLink key={'language-coaching'} href={languageCoaching.link} onClick={closeMenu}>
													{languageCoaching.text}
												</InnerMenuLink>
											)}
										</DropdownContent>
									</Dropdown>,
							  ]
							: [
									<MenuLink key={'services'} href={services.link} onClick={closeMenu}>
										{services.text}
									</MenuLink>,
									<MenuLink key={'business-coaching'} href={businessCoaching.link} onClick={closeMenu}>
										{businessCoaching.text}
									</MenuLink>,
									<MenuLink key={'language-coaching'} href={languageCoaching.link} onClick={closeMenu}>
										{languageCoaching.text}
									</MenuLink>,
							  ]}
						<MenuLink key={'contact'} href={contact.link} onClick={closeMenu}>
							{contact.text}
						</MenuLink>
						<LanguageSelector />
					</MenuItems>
				</NavBar>
			</Wrapper>
		</ThemeProvider>
	);
};

export default Header;
