import hu from '../images/hu.png';
import en from '../images/en.png';

export default [
	{
		iso: 'hu',
		name: 'Magyar',
		image: hu,
	},
	{
		iso: 'en',
		name: 'English',
		image: en,
	},
];
