import React from 'react';
import PropTypes from 'prop-types';

import Header from 'src/components/header';
import { ThemeProvider } from 'styled-components';
import { colors, GlobalStyle } from 'src/theme/globalStyle';

const Layout = ({ children }) => {
	return (
		<ThemeProvider theme={{ colors }}>
			<GlobalStyle />
			<Header />
			<main>{children}</main>
			<footer></footer>
		</ThemeProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
